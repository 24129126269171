.wrapper {
  flex-direction: column;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  align-self: stretch;
}

.titleWraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.title {
  color: var(--warning);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 16px;
}

.subtitle {
  color: var(--warning);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.column {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 0px 32px;
}

.text1 {
  font-size: 16px;
  font-weight: 500;
  color: var(--text1);
}

.text2 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--text2);
}

.actionWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

@media only screen and (max-width: 664px) {
  .title {
    color: var(--text1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 12px;
  }

  .actionWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }

  .column {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 0px 0px;
  }
}
