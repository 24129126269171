.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  color: var(--text1);
  padding: 4px 32px 4px 32px;
}

.pageName {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--text2);
}

.subTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--text2);
  text-transform: capitalize;
}

.arrowIcon {
  height: 16px;
  width: 16px;
  stroke-width: 1.5px;
  color: var(--text2);
  margin: 0px 4px 0px 4px;
}

.rightSlot {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.notifButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: var(--radius);
  color: var(--text2);
}

.notifButton:hover {
  background-color: var(--surface2);
}

.icon {
  height: 18px;
  width: 18px;
}

.bell {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 664px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    color: var(--text1);
    padding: 4px 12px 4px 40px;
  }
}
