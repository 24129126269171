.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  font-size: 18px;
  color: var(--text3);
  text-align: center;
  margin-top: -96px;
}

.img {
  width: 78px;
  height: 78px;
  object-fit: cover;
  color: var(--surface3);
}

.moduleName {
  color: var(--text2);
}

.iconWrapper {
  background-color: var(--surface1);
  padding: 12px;
  border-radius: 16px;
  border: 2px dashed var(--surface3);
}
