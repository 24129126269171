.wrapper {
  display: block;
  width: 100%;
}

.input {
  appearance: none;
  background: var(--surface2);
  border: 1px solid transparent;
  border-radius: var(--radius);
  box-sizing: border-box;
  color: var(--text1);
  font-size: 100%;
  line-height: 1;
  height: var(--touch-target);
  margin-top: var(--pad-xs);
  padding: 0 var(--pad);
  transition: var(--transition);
  transition-property: background-color, border-color;
  width: 100%;
}

.input:hover {
  border-color: var(--surface3);
  outline: 0;
  background: var(--surface1);
}

.input:focus {
  border-color: var(--brand);
  outline: 0;
  background: var(--surface1);
}

.input::placeholder {
  color: var(--text3);
  opacity: 1;
}

.inputS {
  appearance: none;
  background: var(--surface2);
  border: 1px solid transparent;
  border-radius: var(--radius);
  box-sizing: border-box;
  color: var(--text1);
  font-size: 14px;
  line-height: 12;
  height: 32px;
  margin-top: var(--pad-xs);
  padding: 0 12px;
  transition: var(--transition);
  transition-property: background-color, border-color;
  width: 100%;
}

.inputS:hover {
  border-color: var(--surface3);
  outline: 0;
  background: var(--surface1);
}

.inputS:focus {
  border-color: var(--brand);
  outline: 0;
  background: var(--surface1);
}

.inputS::placeholder {
  color: var(--text3);
  opacity: 1;
}

.label {
  color: var(--text2);
  font-size: 13px;
  line-height: 1;
  transition: var(--transition);
  transition-property: color;
}
