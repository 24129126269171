.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding: 4px 0px;
}

.dropZone {
  width: 100%;
  height: 100%;
  border: 1px dashed var(--surface5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text2);
  background-color: var(--surface2);
}

.photo {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  object-fit: cover;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  /* align-items: justify; */
  flex-direction: row;
  width: 100%;
  gap: 12px;
}

.buttonRight {
  display: flex;
  gap: 12px;
}

.loading {
  display: flex;
  align-items: center;
  /* padding-top: 30%; */
  justify-content: center;
  height: 100%;
  width: 100%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 65%;
}

.title {
  color: var(--text1);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 16px;
}

.textInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
}
