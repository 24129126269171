.visible {
  display: flex;
}

.hidden {
  display: none;
}

.conteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 32px;
}

.wrapper {
  flex-direction: column;
  width: 100%;
  max-width: 320px;
}

.blockFirst {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 12px 0px 20px 0px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 20px 0px;
}

.blockLast {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 20px 0px 0px 0px;
}

.blockTitle {
  font-size: 16px;
  font-weight: 500;
}

.blockContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text2);
  font-size: 14px;
}

.blockContentFeature {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  color: var(--text2);
  font-size: 14px;
}

.contentleftUser {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.contentleft {
  display: flex;
  flex-direction: column;
}

.userDescription {
  display: flex;
  flex-direction: column;
}

.userAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--surface3);
}

.textFirst {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.textSecond {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text2);
}

.featureRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 8px;
}

.iconBulet {
  width: 16px;
  height: 16px;
  padding-top: 4px;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text2);
}

.featureTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  color: var(--text1);
}

@media only screen and (max-width: 664px) {
}
