.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.section {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* align-items: flex-start; */
  justify-content: flex-start;
  padding: 0px 32px 32px 32px;
  overflow: scroll;
}

.hugeIcon {
  height: 96px;
  width: 96px;
  stroke-width: 0.6px;
}

.cardBG {
  background-color: var(--surface1) !important;
}

@media only screen and (max-width: 664px) {
  .dashboard {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    /* align-items: flex-start; */
    justify-content: flex-start;
    padding: 0px 12px 32px 12px;
    overflow: scroll;
  }
}
