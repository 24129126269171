.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.section {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  /* justify-content: center; */
  padding: 32px;
  overflow: scroll;
}

.hugeIcon {
  height: 96px;
  width: 96px;
  stroke-width: 0.6px;
}
.actionRow {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: var(--text2);
}

.planGrid {
  max-width: 1280px;
  height: max-content;
  width: 100%;
}

.planPreview {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background-color: var(--surface1);
  border-radius: 8px;
  border: 1px solid var(--surface2);
  transition: all 0.2s ease-in-out;
}

.pageTitle {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text1);
  padding: 0px;
}

.planTitle {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text1);
  padding: 0px;
}

.planDescription {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--text2);
}

.link {
  text-decoration: none;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  color: var(--text2);
  stroke-width: 1.5px;
}

@media only screen and (max-width: 664px) {
  .dashboard {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    /* align-items: flex-start; */
    justify-content: flex-start;
    padding: 24px 12px 32px 12px;
    overflow: scroll;
  }
}
