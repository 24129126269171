/* reset */
button {
  all: unset;
}

.DropdownMenuContent {
  left: 16px;
  min-width: 248px;
  background-color: var(--surface2);
  border-radius: 6px;
  border: 1px solid var(--surface3);
  padding: 0px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 500;
}

.DropdownMenuSubContent {
  left: 16px;
  min-width: 120px;
  background-color: var(--surface2);
  border-radius: 6px;
  padding: 0px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 500;
}

.DropdownMenuContentWrapper {
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  line-height: 1;
  color: var(--text1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  user-select: none;
  outline: none;
}

.DropdownMenuSubTrigger[data-state='open'] {
  background-color: var(--violet-4);
  color: var(--violet-11);
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--text2);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: var(--surface3);
  color: var(--text1);
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: var(--surface2);
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--text3);
}
[data-highlighted] > .RightSlot {
  color: var(--text2);
}
[data-disabled] .RightSlot {
  color: var(--text3);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  /* width: 100%; */
  /* padding: 4px 12px 4px 4px; */
  border-radius: var(--radius);
}

.wrapper:hover {
  background-color: var(--surface2);
  cursor: pointer;
}

.wrapper:focus {
  background-color: var(--surface2);
  cursor: pointer;
}

.logo {
  height: 32px;
  width: 32px;
}

.userInfo {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.userText {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: var(--text2);
}

.userName {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text1);
}

.content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  max-width: 160px;
}

.textContainer {
  white-space: nowrap; /* Не переносить текст на новую строку */
  overflow: hidden; /* Обрезать текст */
  text-overflow: ellipsis; /* Добавить троеточие в конце обрезанного текста */
  width: 100%; /* Занимать всю ширину родителя */
}

.icon {
  height: 8px;
  width: 8px;
  color: var(--text2);
  stroke-width: 1.5px;
}

.iconLeft {
  width: 16px;
  height: 16px;
  stroke-width: 1.5px;
}

.signOutButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.signOutButton:hover {
  color: var(--text1);
  cursor: pointer;
}

@media only screen and (max-width: 664px) {
  .DropdownMenuContent {
    left: 16px;
    min-width: 248px;
    background-color: var(--surface2);
    border-radius: 6px;
    border: 1px solid var(--surface3);
    padding: 0px;
    box-shadow:
      0px 10px 38px -10px rgba(22, 23, 24, 0.35),
      0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    animation: none !important;
    animation-duration: none !important;
    animation-timing-function: none !important;
    will-change: none !important;
    z-index: 500;
  }
}
