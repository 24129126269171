.wrapper {
  flex-direction: column;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  align-self: stretch;
}

.titleWraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 354px;
}

.title {
  color: var(--text1);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 16px;
}

.subtitle {
  color: var(--text2);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.column {
  padding-top: 20px;
  display: flex;
  width: 100%;
  max-width: 354px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.avatarWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  height: 40px;
}

.avatar {
  width: 52px;
  height: 52px;
  border-radius: 52px;
  border: 1px solid var(--border-1, #34343b);
  background-color: var(--surface3, #1e1e21);
}

.avatarText {
  font-size: 16px;
  font-weight: 500;
  color: var(--text2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
}

.name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--text2);
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.button {
  color: var(--brand2);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  text-transform: uppercase;
}

.button:hover {
  border-bottom: 1px dotted var(--brand2);
  cursor: pointer;
}

.button:focus {
  border-bottom: 1px dotted var(--brand2);
  cursor: pointer;
}

.ToastViewport {
  --viewport-padding: 24px;
  position: fixed; /* Здесь мы меняем position на fixed */
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}

.ToastRoot {
  background-color: var(--success);
  border-radius: var(--radius);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 12px 16px;
  display: grid;
  grid-template-areas: 'title action' 'description action';
  grid-template-columns: auto max-content;
  column-gap: 4px;
  align-items: center;
  color: var(--text1);
}
.ToastRoot[data-state='open'] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state='closed'] {
  animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe='cancel'] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe='end'] {
  animation: swipeOut 100ms ease-out;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

.ToastTitle {
  grid-area: title;
  font-weight: 600;
  color: var(--text1);
  font-size: 16px;
  line-height: 1.3;
}

.ToastDescription {
  grid-area: description;
  margin: 0;
  color: var(--text2);
  font-size: 13px;
  line-height: 1.3;
}

.ToastAction {
  grid-area: action;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  font-weight: 500;
}
.Button.small {
  font-size: 12px;
  padding: 0 10px;
  line-height: 25px;
  height: 24px;
}
.Button.large {
  font-size: 15px;
  padding: 0 15px;
  line-height: 35px;
  height: 35px;
}
.Button.violet {
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
  background-color: var(--mauve-3);
}
.Button.violet:focus {
  box-shadow: 0 0 0 2px black;
}
.Button.green {
  background-color: var(--green-2);
  color: var(--green-11);
  box-shadow: inset 0 0 0 1px var(--green-7);
}
.Button.green:hover {
  box-shadow: inset 0 0 0 1px var(--green-8);
}
.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-8);
}

@media only screen and (max-width: 664px) {
  .title {
    color: var(--text1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 12px;
  }

  .column {
    padding-top: 4px;
    display: flex;
    width: 100%;
    max-width: 354px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
