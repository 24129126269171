.wrapper {
  align-items: top;
  background: var(--warning);
  border-radius: var(--radius);
  display: flex;
  gap: var(--pad);
  padding: var(--pad);
  color: var(--text1);
}

.icon {
  color: var(--text1);
  transform: translate(0, 2px);
  width: 20px;
}

.content {
  line-height: 20px;
}
