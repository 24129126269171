.card {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  background-color: var(--surface1);
  border-radius: 8px;
  border: 1px solid var(--surface2);
  transition: all 0.2s ease-in-out;
}

.cardNoPad {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--surface1);
  border-radius: 8px;
  border: 1px solid var(--surface2);
  transition: all 0.2s ease-in-out;
}
