.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.section {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.hugeIcon {
  height: 96px;
  width: 96px;
  stroke-width: 0.6px;
}
