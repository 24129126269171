body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong,
b,
.text--bold {
  font-weight: 700;
}

em,
.text--em {
  font-style: italic;
}

code {
  background: var(--surface2);
  display: inline-block;
  color: var(--yellow);
  font-size: 14px;
  padding: 0 8px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2,
.h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  padding-bottom: 16px;
  padding-top: 8px;
}

h3,
.h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 16px;
  padding-top: 8px;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--text1);
  background: var(--surface3);
}

::selection {
  color: var(--text1);
  background: var(--surface3);
}
