/* reset */
button {
  all: unset;
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  padding: 0 16px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  height: 30px;
  gap: 12px;
  background-color: var(--surface2);
  color: var(--brand2);
  box-shadow: 0 2px 10px var(--black-a7);
  border: 1px solid var(--surface3);
}
.SelectTrigger:hover {
  border: 1px solid var(--brand2);
  cursor: pointer;
}
.SelectTrigger:focus {
  border: 1px solid var(--brand2);
}
.SelectTrigger[data-placeholder] {
  color: var(--brand2);
}

.SelectIcon {
  color: Var(--brand2);
}

.SelectContent {
  overflow: hidden;
  background-color: var(--surface3);
  border-radius: 6px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 900000;
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  color: var(--text1);
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 40px 0 12px;
  position: relative;
  user-select: none;
}
.SelectItem[data-disabled] {
  color: var(--text1);
  pointer-events: none;
}
.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--brand2);
  color: white;
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  /* left: 0; */
  right: 4px;
  width: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}

.SelectTrigger > span {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.SelectTriggerspan > icon {
  width: 16px;
  height: 16px;
  stroke-width: 2px;
}

.visible {
  display: flex;
}

.hidden {
  display: none;
}

.wrapper {
  flex-direction: column;
  width: 100%;
}

.blockFirst {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0px 0px 20px 0px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 20px 0px;
}

.blockLast {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 20px 0px 0px 0px;
}

.blockTitle {
  font-size: 16px;
  font-weight: 500;
}

.blockContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contentleftUser {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.contentleft {
  display: flex;
  flex-direction: column;
}

.userDescription {
  display: flex;
  flex-direction: column;
}

.userAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--surface3);
}

.textFirst {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.textSecond {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text2);
}

.icon {
  width: 16px;
  height: 16px;
  stroke-width: 2px;
}

.icon2 {
  width: 16px;
  height: 16px;
  stroke-width: 2px;
}

.span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.iconText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 664px) {
  .wrapper {
    flex-direction: column;
    width: 100%;
    padding-top: 12px;
  }
}
