.wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  padding-left: 4px;
}

.appLogo {
  height: 24px;
  width: 24px;
}

.appText {
  font-size: 16px;
  font-weight: 600;
  color: var(--text1);
}
