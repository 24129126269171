.planPreview {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rows {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  color: var(--text2);
}

.planTitle {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text1);
  padding: 0px;
}

.planDescription {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--text2);
}

.link {
  text-decoration: none;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: var(--text2);
  padding: 4px 16px;
  stroke-width: 1.5px;
}
