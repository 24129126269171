.appLogo {
  height: 24px;
  width: 24px;
}

.avatar {
  height: 24px;
  width: 24px;
  border-radius: 1000px;
}

.superellipse {
  width: 24px;
  height: 24px;
  position: relative;
  background-color: white;
  background-size: cover; /* Устанавливаем размер фонового изображения */
  background-position: center; /* Выравниваем фоновое изображение по центру */
  border-radius: 18%; /* Это для супер-эллипса */
}

.superellipse::before,
.superellipse::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
}

.superellipse::before {
  border-radius: 2%/30%;
}

.superellipse::after {
  border-radius: 30%/2%;
}

.appLogo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s {
  height: 24px;
  width: 24px;
}

.m {
  height: 32px;
  width: 32px;
}

.l {
  height: 48px;
  width: 48px;
}

.spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
