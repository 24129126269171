.wrapper {
  flex-direction: column;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  align-self: stretch;
}

.titleWraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 454px;
}

.title {
  color: var(--text1);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 16px;
}

.subtitle {
  color: var(--text2);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.column {
  display: flex;
  width: 100%;
  max-width: 454px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.avatarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.avatar {
  width: 52px;
  height: 52px;
  border-radius: 52px;
  border: 1px solid var(--border-1, #34343b);
  background-color: var(--surface3, #1e1e21);
}

.avatarText {
  font-size: 16px;
  font-weight: 500;
  color: var(--text2);
}

.name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--text2);
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.button {
  color: var(--brand2);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  text-transform: uppercase;
}

.button:hover {
  border-bottom: 1px dotted var(--brand2);
  cursor: pointer;
}

.Label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 25px;
  color: var(-text1);
}

.Label:hover {
  cursor: pointer;
}

.description {
  font-size: 14px;
  line-height: 18px;
  color: var(--text2);
  padding-left: 26px;
}

.RadioGroupRoot {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.RadioGroupItem {
  background-color: var(--surface2);
  width: 16px;
  height: 16px;
  border-radius: 100%;
  box-shadow: 0 2px 10px var(--surface3);
  border: 1px solid var(--surface4);
}
.RadioGroupItem:hover {
  background-color: var(--surface3);
}
.RadioGroupItem:focus {
  box-shadow: 0 0 0 2px var(--brand2);
}

.RadioGroupIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.RadioGroupIndicator::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--brand2);
}

.form {
  width: 100%;
}

.radioItemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.titleRadio {
  font-size: 14px;
  line-height: 14px;
  color: var(--text1);
  font-weight: 500;
  /* padding-bottom: 4px; */
}

@media only screen and (max-width: 664px) {
  .title {
    color: var(--text1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 12px;
  }
}
