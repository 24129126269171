/* @import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css'; */

/* reset */
button,
fieldset,
input {
  all: unset;
}

.DrawerContent,
[vaul-drawer] .DrawerContent,
[vaul-drawer][data-state='closed'] .DrawerContent,
[vaul-drawer][vaul-closed-by-dragging='true'] .DrawerContent,
[vaul-overlay] .DrawerContent,
[vaul-overlay][vaul-theme-transition='true'] .DrawerContent,
[vaul-overlay][data-state='closed'] .DrawerContent,
[vaul-overlay][vaul-theme-transition='true'][data-state='closed']
  .DrawerContent {
  animation: none !important;
}

.DrawerOverlay {
  animation: none !important;
}

.DialogOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10000;
  backdrop-filter: blur(1px);
}

.DialogContent {
  display: flex;
  flex-direction: row;
  background-color: var(--surface1);
  backdrop-filter: blur(100px);
  border-radius: 8px;
  border: 1px solid var(--surface3);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 870px;
  height: 620px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10001;
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 20px;
  margin: 0;
  color: var(--text1);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
}

.DrawerOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: 10000;
  backdrop-filter: blur(1px);
}

.DrawerContent {
  display: flex;
  flex-direction: row;
  background-color: var(--surface1);
  backdrop-filter: blur(100px);
  border-radius: 8px;
  border: 1px solid var(--surface3);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 870px;
  height: 620px;
  max-height: 85vh;
  z-index: 10001;
}

.sidebar {
  min-width: 220px;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.windowTitle {
  display: flex;
  padding: 12px 16px 24px 16px;
  align-items: flex-start;
  align-self: stretch;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  height: 100%;
  padding: 16px 20px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  line-height: 1;
  color: var(--text1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  user-select: none;
  outline: none;
}

.item:hover {
  background-color: var(--surface3);
  cursor: pointer;
}

.item:focus {
  background-color: var(--surface3);
}

.icon {
  width: 16px;
  height: 16px;
  stroke-width: 1.5px;
}

.IconButton {
  font-family: inherit;
  border-radius: var(--radius);
  height: 32px;
  width: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text2);
  position: absolute;
  top: 8px;
  right: 8px;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.IconButton:hover {
  background-color: var(--surface2);
  color: var(--text1);
  cursor: pointer;
}

.IconButton:focus {
  background-color: var(--surface2);
  /* box-shadow: 0 0 0 1px var(--brand); */
}

.closeButton {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text3);
  border: 1px solid transparent;
  box-sizing: border-box;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 664px) {
  .DialogContent {
    border-radius: 8px;
    border: 1px solid var(--surface3);
    display: flex;
    flex-direction: column;
    background-color: var(--surface1);
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    max-width: 870px;
    height: 100vh;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 10001;
  }

  .DrawerContent {
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--surface3);
    display: flex;
    flex-direction: column;
    background-color: var(--surface1);
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 60%;
    left: 50%;
    /* right: 0; */
    width: 100vw;
    max-width: 870px;
    height: max-content;
    z-index: 10001;
  }

  .DrawerContent::after {
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid var(--surface3);
    border-right: 1px solid var(--surface3);
  }

  .drawerLineWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .drawerLine {
    height: 4px;
    width: 80px;
    background-color: var(--surface4);
    border-radius: 4px;
    margin: 8px 0;
  }

  .mobileContentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 12px 20px;
  }

  .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0px 0px 24px 0px;
  }

  .mobileTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--text1);
  }

  .backButton {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--text3);
    /* border: 1px solid var(--surface3); */
    padding: 0px 0px 0px 32px;
    border-radius: var(--radius);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    box-sizing: border-box;
  }
}

[vaul-drawer] {
  will-change: none;
  touch-action: none;
  animation: none;
}

[vaul-drawer]::after {
  content: '';
  position: absolute;
  top: 100%;
  background: inherit;
  background-color: inherit;
  left: 0;
  right: 0;
  height: 200%;
}

[vaul-drawer][data-state='closed'] {
  animation: none;
}

[vaul-drawer][vaul-closed-by-dragging='true'] {
  animation: none;
}

[vaul-overlay] {
  animation: none;
}

[vaul-overlay][vaul-theme-transition='true'] {
  animation: none;
}

[vaul-overlay][data-state='closed'] {
  animation: none;
}

[vaul-overlay][vaul-theme-transition='true'][data-state='closed'] {
  animation: none;
}
.DrawerContent,
[vaul-drawer] .DrawerContent,
[vaul-drawer][data-state='closed'] .DrawerContent,
[vaul-drawer][vaul-closed-by-dragging='true'] .DrawerContent,
[vaul-overlay] .DrawerContent,
[vaul-overlay][vaul-theme-transition='true'] .DrawerContent,
[vaul-overlay][data-state='closed'] .DrawerContent,
[vaul-overlay][vaul-theme-transition='true'][data-state='closed']
  .DrawerContent {
  animation: none;
}
