.column {
  margin: 0 auto;
  max-width: 420px;
  padding: calc(var(--gutter) / 2);
}

.formHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 var(--pad-l);
  gap: var(--pad-l);
}

.authLinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.appLogo {
  height: 40px;
  width: 40px;
}

.title {
  font-weight: 400;
  font-size: 32px;
}
.subtitle {
  padding: 20px 0px;
}

@media only screen and (max-width: 664px) {
  .formHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px var(--pad-l);
    gap: var(--pad-l);
  }
}
