button {
  all: unset;
}

.wrapper {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  transition: color 0.2s ease-out;
  border-radius: var(--radius);
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke-width: 1.5px;
}

.buttonS {
  height: 24px;
  width: 24px;
}

.buttonM {
  min-height: 32px;
  min-width: 32px;
}

.buttonL {
  height: 44px;
  width: 44px;
}

.wrapper:hover {
  background-color: var(--surface2);
  color: var(--brand2);
}

.wrapper:focus {
  background-color: var(--surface2);
  color: var(--brand2);
}

.wrapper > svg {
  height: 16px;
  width: 16px;
}

.primary {
  border-radius: var(--radius);
  /* border: 1px solid var(--surface3); */
  background: var(--surface2);
  color: var(--brand2);
}

.primary:hover {
  background: var(--surface2);
  /* border: 1px solid var(--brand2); */
}

.primary:focus {
  background: var(--surface2);
  /* border: 1px solid var(--brand2); */
}

.secondary {
  color: var(--text2);
}

.secondary:hover {
  color: var(--text1);
}

.secondary:focus {
  color: var(--text1);
}

.isActive {
  color: var(--text1);
  background-color: var(--surface2);
}

@media (min-width: 800px) {
  .wrapperHiddenLg {
    display: none;
  }
}
