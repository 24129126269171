.menuItem {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.menuItem:hover {
  background-color: var(--surface2);
  cursor: pointer;
}

.menuItemActive {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background-color: var(--surface3);
}

.menuItemActive:focus-visible {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background-color: var(--brand);
  color: white;
}
